import React, { useEffect, useState } from 'react';

// CSS styles can be moved to a separate CSS file and imported, or you can use inline styles or CSS-in-JS solutions like styled-components
const episodeStyle = {
  minWidth: 'max-content',
  marginBottom: '.8rem',
  padding: '.8rem 1rem',
  borderRadius: '10px',
  border: '0',
  background: '#191414',
  color: '#fff',
  cursor: 'pointer',
};

const episodeHoverStyle = {
  background: '#1Db954',
};

const episodesContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const SpotifyEmbed = () => {
  const [activeEpisode, setActiveEpisode] = useState('spotify:episode:7makk4oTQel546B0PZlDM5');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://open.spotify.com/embed/iframe-api/v1';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.onSpotifyIframeApiReady = (IFrameAPI) => {
        const element = document.getElementById('embed-iframe');
        const options = {
          width: '100%',
          height: '160',
          uri: activeEpisode,
        };
        const callback = (EmbedController) => {
          // This setup function can be expanded or modified as needed.
        };
        IFrameAPI.createController(element, options, callback);
      };
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [activeEpisode]); // Re-run this effect if the active episode changes

  return (
    <div>
      <div style={episodesContainerStyle}>
        {[
          { id: 'spotify:episode:7makk4oTQel546B0PZlDM5', title: 'My Path to Spotify: Women in Engineering' },
          { id: 'spotify:episode:43cbJh4ccRD7lzM2730YK3', title: 'What is Backstage?' },
          { id: 'spotify:episode:6I3ZzCxRhRkNqnQNo8AZPV', title: 'Introducing Nerd Out@Spotify' },
        ].map(episode => (
          <button
            key={episode.id}
            style={episodeStyle}
            onMouseOver={(e) => e.currentTarget.style.background = episodeHoverStyle.background}
            onMouseOut={(e) => e.currentTarget.style.background = episodeStyle.background}
            onClick={() => setActiveEpisode(episode.id)}
          >
            {episode.title}
          </button>
        ))}
      </div>

      <div id="embed-iframe"></div>
    </div>
  );
};

export default SpotifyEmbed;
