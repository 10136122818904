import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, Box, AppBar, List, ListItemButton, ListItemText, ListItemIcon, TextField, Button, Stack, IconButton, ButtonGroup, Slider, Typography, CssBaseline, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PlayerContainer from './components/PlayerContainer';
import Timeline from './components/Timeline';
import SpotifyFrame from './components/SpotifyFrame';
import Birthday from './components/Birthday';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import BirthdayInput from './components/BirthdayInput';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddEventModal from './components/AddEventModal';

import MenuIcon from '@mui/icons-material/Menu';  // Import MenuIcon

// Create a dark theme inspired by Spotify
const spotifyTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1DB954',
    },
    background: {
      default: '#121212',
      paper: '#181818',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B3B3B3',
    },
  },
  typography: {
    fontFamily: '"Circular", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 500,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
          },
        },
      },
    },
  },
});

export default function App() {
  const [birthDate, setBirthDate] = useState(null);
  const [chartDate, setChartDate] = React.useState('2002-07');
  const [customEvents, setCustomEvents] = useState([]);
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);

  // Get the data from the public folder for the date we are looking for
  React.useEffect(() => {
    fetch('charts/' + chartDate + '.json')
      .then(response => response.json())
      .then(apiData => {
        setData(apiData);
        if (apiData.length > 0) {
          // Select the first item in the list
          setSelectedIndex(apiData[0].unique);
          setUrl(apiData[0].url);
        }
      })
  }, [chartDate])

  // Setup the URL for the player
  const [url, setUrl] = React.useState('');

  // Set the selected item in the list
  const [selectedIndex, setSelectedIndex] = React.useState('');

  // Setup the data storage
  const [apiData, setData] = React.useState([])

  // Setup the variables for the month and year form
  const [inputValues, setInputValues] = React.useState({
    formMonth: chartDate.slice(5, 7),
    formYear: chartDate.slice(0, 4)
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues(prev => ({ ...prev, [name]: value }));
  };

  const handleInputBlur = () => {
    const newChartDate = `${inputValues.formYear}-${inputValues.formMonth}`;
    if (newChartDate !== chartDate) {
      setChartDate(newChartDate);
    }
  };

  const handleKeyPress = React.useCallback((event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  }, []);

  // Update ListContainer
  function ListContainer(props) {
    return (
      apiData.length > 0 ? (
        <Box sx={{ my: 2 }}>
          <MonthYearPicker 
            value={chartDate}
            onChange={setChartDate}
          />
          <List dense={true} aria-label="main" sx={{ pt: 1 }}>
            {apiData.map((track, index) => (
              <ListItemButton
                sx={{
                  py: 0.5,
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  },
                }}
                key={track.unique}
                onClick={() => { handleTrackClick(track) }}
                selected={selectedIndex === track.unique}
              >
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '0.75rem' }}>{index + 1}</Typography>
                </ListItemIcon>
                <ListItemText
                  primary={track.title}
                  secondary={track.artist}
                  primaryTypographyProps={{
                    noWrap: true,
                    fontSize: '0.875rem',
                    fontWeight: 'medium',
                  }}
                  secondaryTypographyProps={{
                    noWrap: true,
                    fontSize: '0.75rem',
                    color: 'text.secondary',
                  }}
                />
                {selectedIndex === track.unique && (
                  <AudiotrackIcon sx={{ color: 'primary.main', ml: 2, fontSize: '1rem' }} />
                )}
              </ListItemButton>
            ))}
          </List>
        </Box>
      ) : <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>Loading...</Typography>
    );
  }

  // Update handleYearChange and handleMonthChange
  function handleYearChange(change) {
    const newYear = String(parseInt(inputValues.formYear) + change);
    setInputValues(prev => ({ ...prev, formYear: newYear }));
    setChartDate(`${newYear}-${inputValues.formMonth}`);
  }

  function handleMonthChange(change) {
    let newMonth = parseInt(inputValues.formMonth) + change;
    let newYear = parseInt(inputValues.formYear);
    
    if (newMonth < 1) {
      newMonth = 12;
      newYear--;
    } else if (newMonth > 12) {
      newMonth = 1;
      newYear++;
    }
    
    newMonth = newMonth < 10 ? `0${newMonth}` : `${newMonth}`;
    newYear = `${newYear}`;
    
    setInputValues({ formMonth: newMonth, formYear: newYear });
    setChartDate(`${newYear}-${newMonth}`);
  }

  function handleSetSpecificDate(specificMonth, specificYear) {
    const year = parseInt(specificYear);
    setInputValues({ formMonth: specificMonth, formYear: year.toString() });
    setChartDate(`${year}-${specificMonth}`);
  }

  function handleTrackClick(track) {
    setUrl(track.url);
    setSelectedIndex(track.unique);
  }

  function handleTimelineChange(year, month) {
    const formattedMonth = month.toString().padStart(2, '0');
    const newChartDate = `${year}-${formattedMonth}`;
    setInputValues({ formYear: year.toString(), formMonth: formattedMonth });
    setChartDate(newChartDate);
  }

  function MonthYearPicker({ value, onChange }) {
    const date = dayjs(value);

    const handleYearChange = (newDate) => {
      onChange(newDate.format('YYYY-MM'));
    };

    const handleMonthChange = (newDate) => {
      onChange(newDate.format('YYYY-MM'));
    };

    const incrementYear = () => {
      onChange(date.add(1, 'year').format('YYYY-MM'));
    };

    const decrementYear = () => {
      onChange(date.subtract(1, 'year').format('YYYY-MM'));
    };

    const incrementMonth = () => {
      onChange(date.add(1, 'month').format('YYYY-MM'));
    };

    const decrementMonth = () => {
      onChange(date.subtract(1, 'month').format('YYYY-MM'));
    };

    const CustomInput = React.forwardRef(({ value, onClick, ...other }, ref) => (
      <Button
        ref={ref}
        onClick={onClick}
        variant="outlined"
        size="small"
        sx={{
          minWidth: 'auto',
          padding: '2px 8px',
          fontSize: '0.75rem',
        }}
        {...other}
      >
        {value}
      </Button>
    ));

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <IconButton size="small" onClick={decrementMonth}>
            <RemoveCircleOutlineIcon fontSize="small" />
          </IconButton>
          <DatePicker
            sx={{
              width: 150
            }}
            views={['month']}
            minDate={dayjs('1953-01-01')}
            maxDate={dayjs('2021-12-31')}
            value={date}
            onChange={handleMonthChange}
            renderInput={(params) => <CustomInput {...params} />}
            components={{
              OpenPickerIcon: () => null,
            }}
            inputFormat="MMM"
          />
          <IconButton size="small" onClick={incrementMonth}>
            <AddCircleOutlineIcon fontSize="small" />
          </IconButton>

          <IconButton size="small" onClick={decrementYear}>
            <RemoveCircleOutlineIcon fontSize="small" />
          </IconButton>
          <DatePicker
            sx={{
              width: 150,
            }}
            views={['year']}
            minDate={dayjs('1953-01-01')}
            maxDate={dayjs('2021-12-31')}
            value={date}
            onChange={handleYearChange}
            renderInput={(params) => <CustomInput {...params} />}
            components={{
              OpenPickerIcon: () => null,
            }}
            inputFormat="YYYY"
          />
          <IconButton size="small" onClick={incrementYear}>
            <AddCircleOutlineIcon fontSize="small" />
          </IconButton>
        </Box>
      </LocalizationProvider>
    );
  }

  const handleBirthdaySubmit = (date) => {
    setBirthDate(date);
    const birthYear = parseInt(date.slice(0, 4));
    const birthMonth = date.slice(5, 7);
    setChartDate(`${birthYear + 14}-${birthMonth}`);  // Set the chart date to the date when the user turns 14
  };

  const handleAddEvent = (newEvent) => {
    setCustomEvents([...customEvents, newEvent]);
  };

  const generateDateButtons = () => {
    if (!birthDate) return null;

    const birthYear = parseInt(birthDate.slice(0, 4));
    const birthMonth = birthDate.slice(5, 7);

    const defaultButtons = [
      { name: '👶 Born', date: birthDate },
      { name: '🎂 14', date: `${birthYear + 14}-${birthMonth}` },
      { name: '🎂 16', date: `${birthYear + 16}-${birthMonth}` },
      { name: '🎂 18', date: `${birthYear + 18}-${birthMonth}` },
      { name: '🎂 21', date: `${birthYear + 21}-${birthMonth}` },
    ];

    const allEvents = [...defaultButtons, ...customEvents];

    return (
      <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
        {allEvents.map((event, index) => (
          <Button
            key={index}
            size="small"
            variant="contained"
            onClick={() => handleSetSpecificDate(event.date.slice(5, 7), event.date.slice(0, 4))}
            sx={{
              backgroundColor: event.date === chartDate ? 'white' : 'primary.main',
              color: event.date === chartDate ? 'primary.main' : 'white',
              '&:hover': {
                backgroundColor: event.date === chartDate ? 'white' : 'primary.dark',
              },
            }}
          >
            {event.name}
          </Button>
        ))}
        <Button
          variant="contained"
          size="small"
          onClick={() => setIsAddEventModalOpen(true)}
          startIcon={<MenuIcon />}  // Use MenuIcon instead of AddCircleIcon
          sx={{
            backgroundColor: 'white',
            color: 'primary.main',
            '&:hover': {
              backgroundColor: 'white',
              opacity: 0.9,
            },
          }}
        >
          Remix
        </Button>
      </Stack>
    );
  };

  if (!birthDate) {
    return (
      <ThemeProvider theme={spotifyTheme}>
        <CssBaseline />
        <BirthdayInput onBirthdaySubmit={handleBirthdaySubmit} />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={spotifyTheme}>
      <CssBaseline />
      <Router>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <AppBar position='sticky' sx={{ bgcolor: 'background.paper', boxShadow: 'none', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
            <Container maxWidth="md" sx={{ py: 2 }}>
              <Typography variant="h5" component="h1" sx={{ mb: 2, fontWeight: 'bold' }}>Throwbacks 🎶</Typography>
              {generateDateButtons()}
            </Container>
          </AppBar>

          <Container maxWidth="md" sx={{ flexGrow: 1, mt: 3, mb: 10 }}>
            <Routes>
              <Route path="/" element={<ListContainer />} />
            </Routes>
          </Container>

          <Box position='fixed' sx={{ bottom: 0, left: 0, right: 0, bgcolor: 'background.paper', borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}>
            <Container maxWidth="md">
              <PlayerContainer src={url} />
            </Container>
          </Box>

          <AddEventModal
            open={isAddEventModalOpen}
            handleClose={() => setIsAddEventModalOpen(false)}
            addEvent={handleAddEvent}
          />
        </Box>
      </Router>
    </ThemeProvider>
  );
}