import React from 'react';
import Box from '@mui/material/Box';

function Timeline() {
    return (
      <Box sx={{ my: 4 }}>
        <h1>Timeline</h1>
      </Box>
    )
  }

export default Timeline;
