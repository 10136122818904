import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function Birthday() {
  return (
    <Container>
      <Typography variant="h2">Birthday</Typography>

      
    </Container>
  );
}

export default Birthday;
