import React, { useState } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';  // Add TextField to imports
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function BirthdayInput({ onBirthdaySubmit }) {
  const [birthDate, setBirthDate] = useState(null);

  const handleSubmit = () => {
    if (birthDate) {
      onBirthdaySubmit(birthDate.format('YYYY-MM'));
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', // Center vertically
      height: '100vh', // Full viewport height
      gap: 2, 
      mt: 4 
    }}>
      <Box sx={{ backgroundColor: '#1DB954', padding: 2, borderRadius: 2 }}>
        <Typography variant="h4">Throwbacks 🎶</Typography>
      </Box>
      <Typography>
        Music was <span style={{ fontWeight: 'bold', color:'#1DB954', fontStyle: 'italic' }}>way</span> better when you were young.
      </Typography>
      <Typography>
        Enter your date of birth and let's dive in.
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={['year', 'month']}
          minDate={dayjs('1953-01-01')}
          maxDate={dayjs('2021-12-31')}
          format="MMMM YYYY"
          value={birthDate}
          onChange={(newValue) => setBirthDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Button variant="contained" onClick={handleSubmit} disabled={!birthDate}>
        Show me!
      </Button>
    </Box>
  );
}

export default BirthdayInput;