import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, TextField, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const presetEvents = [
  { name: '📚 Start University' },
  { name: '🎓 Graduation' },
  { name: '💼 First Job' },
  { name: '💍 Engagement' },
  { name: '👰 Wedding' },
  { name: '🏠 First Home' },
  { name: '👶 First Child' },
  { name: '✨ Custom' },
];

export default function AddEventModal({ open, handleClose, addEvent }) {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [customEventName, setCustomEventName] = useState('');
  const [date, setDate] = useState(dayjs());

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    if (event.name !== '✨ Custom') {
      setCustomEventName('');
    }
  };

  const handleSubmit = () => {
    if (selectedEvent) {
      const eventName = selectedEvent.name === '✨ Custom' ? customEventName : selectedEvent.name;
      addEvent({
        name: eventName,
        date: date.format('YYYY-MM'),
      });
      handleClose();
      setSelectedEvent(null);
      setCustomEventName('');
      setDate(dayjs());
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {presetEvents.map((event) => (
              <Button
                key={event.name}
                variant={selectedEvent === event ? 'contained' : 'outlined'}
                onClick={() => handleEventSelect(event)}
              >
                {event.name}
              </Button>
            ))}
          </Stack>
          {selectedEvent && selectedEvent.name === '✨ Custom' && (
            <TextField
              label="Custom Event Name"
              value={customEventName}
              onChange={(e) => setCustomEventName(e.target.value)}
              fullWidth
            />
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Event Month and Year"
              views={['month', 'year']}
              value={date}
              onChange={(newDate) => setDate(newDate)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          disabled={!selectedEvent || (selectedEvent.name === '✨ Custom' && !customEventName)}
        >
          Add Event
        </Button>
      </DialogActions>
    </Dialog>
  );
}