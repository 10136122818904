import React from 'react';

export default function PlayerContainer(props) {

    return (
        <div>
            <iframe src={props.src} height="90px" width="100%" style={{ border: 0 }}/>
        </div>
    );

}